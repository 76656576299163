<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">{{ $t("messages.userRegister").toUpperCase() }}</h1>
    <b-form ref="formAdd">
      <div class="row p-3 mb-5">
        <!-- identificacion -->
        <!-- <b-form-group
          :label="$t('messages.identification') + ' *'"
          label-for="identificacion"
          class="col-md-6 col-lg-4 required"
        >
          <b-form-input
            id="identificacion"
            v-model.trim="form.identificacion"
            type="text"
            ref="identificacion"
            :state="identificacionState"
            required
            :disabled="inProcess"
          ></b-form-input>
        </b-form-group> -->
        <!-- nombres -->
        <b-form-group :label="$t('messages.firstnames') + ' *'" label-for="nombres" class="col-md-6 col-lg-4">
          <b-form-input id="nombres" v-model.trim="form.nombres" type="text" ref="nombres" :state="nombresState" required
            :disabled="inProcess"></b-form-input>
        </b-form-group>
        <!-- apellidos -->
        <b-form-group :label="$t('messages.lastnames') + ' *'" label-for="apellidos" class="col-md-6 col-lg-4">
          <b-form-input id="apellidos" v-model.trim="form.apellidos" type="text" ref="apellidos" :state="apellidosState"
            required :disabled="inProcess"></b-form-input>
        </b-form-group>
        <!-- email -->
        <b-form-group :label="$t('messages.email') + ' *'" label-for="email" class="col-md-6 col-lg-4">
          <b-form-input id="email" v-model.trim="form.email" type="email" ref="email" :state="emailState" required
            :disabled="inProcess || type == 'edit'"></b-form-input>
        </b-form-group>
        <!-- telefono -->
        <b-form-group :label="$t('messages.phone')" label-for="telefono" class="col-md-6 col-lg-4">
          <b-form-input id="telefono" v-model.trim="form.telefono" type="text" ref="telefono" placeholder="+(XX)XXX-XXXXXXX"
            :disabled="inProcess"></b-form-input>
          <b-form-text id="input-live-help">{{
            $t("messages.phoneExplication")
          }}</b-form-text>
        </b-form-group>
        <!-- nacimiento	 -->
        <b-form-group :label="$t('messages.birthdate') + ' *'" label-for="nacimiento" class="col-md-6 col-lg-4">
          <b-form-input id="nacimiento" v-model.trim="form.nacimiento" type="date" ref="nacimiento" :min="minDateBirthday"
            :max="maxDateBirthday" :disabled="inProcess" :state="birthdayState" required></b-form-input>
          <b-form-text id="input-live-help">{{
            $t("messages.birthdayExplication")
          }}</b-form-text>
        </b-form-group>
        <b-form-group id="verificacion"
          :label="`${$i18n.locale === 'es' ? '¿' : ''}${$t('messages.howMuchIs')} ${num1} + ${num2}?  *`"
          label-for="verifiacion" class="col-md-6 col-lg-4">
          <b-form-input id="verificacion" v-model.number="verificacion" type="number" placeholder="verificacion"
            ref="verificacion" :state="verificacionState" required></b-form-input>
        </b-form-group>
        <!-- newsletter	 -->
        <b-form-group class="col-sm-12 mt-5" style="display: flex; justify-content: left">
          <b-form-checkbox id="newsletter" name="newsletter" v-model="newsletter" ref="newsletter">{{
            $t("messages.IWantNewsletter") }}</b-form-checkbox>
        </b-form-group>
      </div>
      <b-button variant="secondary" :disabled="inProcess" @click="$router.push({ name: 'Home' })">{{ $t("messages.cancel")
      }}</b-button>
      <b-button class="ml-3" variant="primary" @click="handleSubmit" :disabled="inProcess">
        {{ $t("messages.save") }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import UsersService from "@/services/users.service";
import EventBus from "@/services/eventBus.js";
import { mapMutations } from "vuex";
import { Helpers } from "@/mixins/Helpers";
import NewsletterService from "@/services/newsletter.service.js";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "UserProcessPublic",
  mixins: [Helpers],
  data() {
    return {
      usersService: null,
      form: {
        identificacion: null,
        nombres: null,
        apellidos: null,
        email: null,
        telefono: null,
        nacimiento: null,
      },
      newsletter: true,
      identificacionState: null,
      nombresState: null,
      apellidosState: null,
      emailState: null,
      birthdayState: null,
      birthdayStateOld: null,
      type: "create",
      minDateBirthday: this.$moment()
        .subtract(100, "years")
        .format("YYYY-MM-DD"),
      maxDateBirthday: this.$moment()
        .subtract(18, "years")
        .format("YYYY-MM-DD"),
      inProcess: false,
      verificacionState: null,
      verificacion: 0,
      num1: this.getRandomArbitrary(1, 20),
      num2: this.getRandomArbitrary(1, 20),
      newsletterService: null,
    };
  },
  created() {
    this.usersService = new UsersService();
    this.newsletterService = new NewsletterService();
  },
  mounted() {
    window.scrollTo(0, 0);
    this.form.identificacion= uuidv4()
  },
  methods: {
    ...mapMutations(["setUserData"]),
    getRandomArbitrary(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    handleSubmit() {
      if (this.form.telefono?.length == 0) {
        this.form.telefono = null;
      }
      if (this.form.nacimiento?.length == 0) {
        this.form.nacimiento = null;
      }
      if (!this.validation()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.$t("messages.completeTheFields"),
        });
        return false;
      }
      this.createRegister();
    },
    createRegister() {
      if (this.newsletter) {
        try {
          this.newsletterService.suscribe({
            email: this.form.email,
            idioma: this.$i18n.locale === "es" ? "spanish" : "english",
          });
        } catch (error) {
          console.log(error);
        }
      }
      this.inProcess = true;

      if(this.form.telefono && this.form.telefono.length > 0 && this.form.telefono[0]!= "+"){
        this.form.telefono = "+" + this.form.telefono;
      }

      this.usersService
        .createUserPublic({
          ...this.form,
          idioma: this.$i18n.locale === "es" ? "spanish" : "english",
        })
        .then((result) => {
          if (result.data.status == 200) {
            this.form = {
              identificacion: uuidv4(),
              nombres: null,
              apellidos: null,
              email: null,
              telefono: null,
              nacimiento: null,
            };
            EventBus.$emit("MOSTRAR_TOAST", {
              title: `${this.$t(
                "messages.welcome"
              )} ${result.data.data.nombres.toUpperCase()} ${result.data.data.apellidos.toUpperCase()}`,
              variant: "success",
              message: this.$t("messages.automaticLogin"),
            });
            this.setUserData(result.data.data);
            this.$router.push({ name: "Home" });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: this.translateErrors(result.data.errores).join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch((error) => {
          console.log(error);
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: this.$t("messages.saveError"),
          });
          this.inProcess = false;
        });
    },
    validation() {
      /* nombres*/
      const validNombres = this.$refs.nombres.checkValidity();
      this.nombresState = validNombres;
      /* apellidos*/
      const validApellidos = this.$refs.apellidos.checkValidity();
      this.apellidosState = validApellidos;
      /* email*/
      const validEmail = this.$refs.email.checkValidity();
      this.emailState = validEmail;
      /* birthday*/
      let validBirthday = this.$refs.nacimiento.checkValidity();
      this.birthdayState = validBirthday;
      this.birthdayStateOld = true;

      //is 18 years old
      if (this.form.nacimiento) {
        const birthday = this.$moment(this.form.nacimiento);
        const today = this.$moment();
        const years = today.diff(birthday, "years");        
        if (years < 18) {
          this.birthdayState = false;
          validBirthday = false;
          this.birthdayStateOld = false;
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "warning",
            message: this.$t("messages.rememberBeMayorAge"),
          });
        }
      }

      /* verificacion */
      const validVerificacion =
        this.$refs.verificacion.checkValidity() &&
        this.verificacion === this.num1 + this.num2;
      this.verificacionState = validVerificacion;
      return validNombres && validApellidos && validEmail && validVerificacion && validBirthday;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding: 0px 300px;
}

//add * in labels with class name required-field

@media (max-width: 1400px) {
  .container-cental {
    padding: 0px 30px 20px 30px;
  }
}
</style>
